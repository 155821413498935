@import "../../assets/styles/main.scss";

.appBannerWrap {
  display: none;
}
.headerWrapper {
  position: sticky;
  top: 0;
  z-index: 999;
}
.header {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  color: $base;
  line-height: 1.65;

  &.ja {
    font-family: -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN",
      "Hiragino Sans", Meiryo, sans-serif;
  }

  &.en {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
      Arial, sans-serif;
  }

  &.zh_tw {
    font-family: "Helvetica Neue", Arial, "Microsoft JhengHei", "PingFang TC",
      "Noto Sans TC", sans-serif;
  }

  &.zh_hk {
    font-family: "Helvetica Neue", Arial, "Microsoft JhengHei", "PingFang HK",
      "Noto Sans HK", sans-serif;
  }

  &.zh_cn {
    font-family: "Helvetica Neue", Arial, "Microsoft YaHei", "PingFang SC",
      "Source Han Sans SC", sans-serif;
  }

  &.au {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
      Arial, sans-serif;
  }

  height: 60px;
  background-color: $white;
  display: grid;
  grid-template-areas: "products logo contentCenter contentRight menu";
  grid-template-columns: 44px auto 1fr;
  padding: 0 30px 0 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;

  .products {
    cursor: pointer;
    grid-area: products;
    display: flex;
    align-items: center;
    z-index: 999;

    .productsBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      margin-bottom: 0;

      .hamburger {
        display: block;
        height: 2px;
        width: 22px;
        border-radius: 2px;
        background-color: $base;
        position: relative;
        transition: all 0.2s;

        &::before {
          content: "";
          display: block;
          height: 2px;
          width: 22px;
          border-radius: 2px;
          background-color: $base;
          position: absolute;
          top: -8px;
          transition: all 0.2s;
        }

        &::after {
          content: "";
          display: block;
          height: 2px;
          width: 17px;
          border-radius: 2px;
          background-color: $base;
          position: absolute;
          top: 8px;
          transition: all 0.2s;
        }
      }
      .cross {
        background-color: transparent;

        &::before {
          transform: rotate(45deg);
          transform-origin: -1px 4px;
        }

        &::after {
          transform: rotate(-45deg);
          transform-origin: 2px -1px;
          width: 22px;
        }
      }
    }

    .productList {
      position: absolute;
      top: 60px;
      left: -290px;
      width: 290px;
      height: calc(100vh - 60px);
      margin: 0;
      padding: 0;
      background-color: $white;
      transition: all 0.2s;
      overflow-y: auto;

      &::before {
        content: "";
        pointer-events: none;
        width: 100vw;
        height: calc(100vh - 60px);
        position: fixed;
        top: 60px;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s;
      }

      .list {
        display: flex;
        align-items: center;
        background-color: $white;

        &:first-of-type {
          margin-top: 40px;
        }

        &.waTop {
          @include media-breakpoint-up(sm) {
            margin-top: 0;
          }
        }

        .anchor {
          @include font-size(15);
          font-weight: 400;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $base;
          width: 100%;
          padding: 10px 16px;
          transition: all 0.2s;

          .icon {
            @include font-size(28);
            font-family: "icomoon";
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            background-color: $gray-100;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            transition: all 0.2s;
          }

          &:hover {
            text-decoration: none;
            color: $primary;
            background-color: $pink-100;

            .icon {
              background-color: $white;
            }
          }
        }

        .top::before {
          color: $primary !important;
        }
      }

      .top {
        margin: 20px 15px;
        width: 100%;
        padding: 5px 0;
        display: flex;
        justify-content: center;
        text-decoration: none;
        border-radius: 3px;
        border: 1px solid $pink-600;
        color: $pink-600;
        @include font-size(15);
        line-height: 165%;
        transition: all 0.2s;
        &:hover {
          background: rgba(217, 74, 69, 0.08);
        }
      }

      .campaign {
        display: flex;
        width: 100%;
        padding: 13.5px 0px;
        justify-content: center;
        margin: 30px 20px 0;
        background: $pink-600;
        color: $white;
        @include font-size(16);
        font-weight: 700;
        line-height: 120%;
        transition: all 0.2s;
        text-decoration: none;
        &:hover {
          background: $white;
          color: $pink-600;
          border: 1px solid $pink-600;
        }
      }

      .currentProduct {
        background-color: $gray-200;
        .anchor {
          pointer-events: none;
        }
      }
    }

    .listOpen {
      left: 0;

      &::before {
        pointer-events: auto;
        opacity: 1;
      }
    }
  }

  &.kaimono {
    .products {
      .list {
        &:first-of-type {
          margin-top: 10px;
        }
      }
    }
  }

  .logo {
    grid-area: logo;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin: 0;

    .logoLink {
      display: inline-flex;
      text-decoration: none;

      .logoPicture {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .logoImg {
        height: 50px;
        &.activityJa {
          width: 300px;
          height: 23px;
        }

        &__jpTokyuText {
          display: none;
        }
      }
    }
  }

  .contentCenter {
    grid-area: contentCenter;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contentRight {
    grid-area: contentRight;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu {
    grid-area: menu;
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;

    .menuBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      color: $base;
      margin-left: 10px;
      transition: all 0.2s;

      &:hover {
        text-decoration: none;
        color: $primary;
      }

      .menuBtnIcon {
        @include font-size(34);

        cursor: pointer;
      }

      .pink {
        color: $primary;
      }
    }
  }

  .userMenuList {
    position: absolute;
    top: 60px;
    right: -5px;
    width: 220px;
    background-color: $white;
    box-shadow: 1px 3px 10px 2px rgba(66, 66, 66, 0.25);
    border-radius: 6px;
    z-index: 1000;

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      background-color: $white;
      display: block;
      transform: rotate(45deg);
      position: absolute;
      top: -6px;
      right: 21px;
      box-shadow: -1px -1px 1px rgba(66, 66, 66, 0.15);
    }

    .userInfo {
      border-bottom: 1px solid $gray-200;
      display: grid;
      grid-template-columns: 40px auto;
      align-items: center;
      padding: 15px;
      line-height: 1.3;

      .iconWamazingFilled {
        @include font-size(20);
        color: $primary;
        border: 2px solid $primary;
        border-radius: 50%;
        padding: 3px 4px 5px 4px;
      }

      .userId {
        @include font-size(12);
        font-weight: bold;
      }

      .userEmail {
        @include font-size(12);
        color: $gray-500;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 150px;
      }
    }

    .userMenuItems {
      list-style: none;
      margin: 0;
      padding: 0;

      .list {
        &:first-of-type {
          padding-top: 8px;
        }
        &:last-of-type {
          padding-bottom: 8px;
        }

        .anchor {
          @include font-size(15);
          font-weight: 400;
          color: $base;
          display: block;
          text-decoration: none;
          padding: 10px 16px;
          transition: 0.2s all;

          &:hover {
            background-color: $pink-100;
            color: $primary;
          }
        }
        .logout {
          background-color: $gray-100;

          &:hover {
            background-color: $pink-100;
          }
        }
      }
    }
  }
  &.activity {
    .products {
      .productList {
        .list {
          .top {
            @include font-size-activity(15);
          }
          .anchor {
            @include font-size-activity(15);
            .icon {
              @include font-size-activity(28);
            }
          }
          .campaign {
            @include font-size-activity(16);
          }
        }
      }
    }
    .userMenuList {
      .userInfo {
        .iconWamazingFilled {
          @include font-size-activity(20);
        }
        .userId {
          @include font-size-activity(12);
        }
        .userEmail {
          @include font-size-activity(12);
        }
      }
      .userMenuItems {
        .list {
          .anchor {
            @include font-size-activity(15);
          }
        }
      }
    }
    .menu {
      .menuBtn {
        .menuBtnIcon {
          @include font-size-activity(34);
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .header {
    height: 50px;
    padding: 4px 10px 4px 5px;
    align-items: center;

    .logo {
      padding-left: 0;

      .logoLink {
        .logoImg {
          height: 44px;
          &.activityJa {
            width: 278px;
            height: 21px;
          }
        }
      }
    }
    .products {
      .productList {
        top: 50px;

        &::before {
          top: 50px;
        }
      }
    }
    .menu {
      .menuBtn {
        .menuBtnIcon {
          @include font-size(31);
        }
      }
      .userMenuList {
        top: 50px;
        right: 2px;

        &::before {
          right: 15px;
        }
      }
    }
    &.activity {
      .menu {
        .menuBtn {
          .menuBtnIcon {
            @include font-size-activity(31);
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .appBannerWrap {
    display: flex;
    background-color: $white;
    .container {
      position: relative;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 75px;
      align-items: center;
      gap: 8px;
      padding: 8px;
      background-color: $pink-100;
      border-radius: 4px;
      .closeIconContainer {
        width: 8px;
        height: 8px;
        display: grid;
        place-content: center;
      }
      .logoContainer {
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 2px 0px $gray-200;
        background-color: $white;
        border-radius: 4px;
        img {
          border-radius: 4px;
        }
      }
      .titleContainer {
        display: block;
        text-align: left;
        .title {
          font-size: 15px;
          font-weight: 600;
          text-align: left;
          span {
            width: max-content;
          }
          .pink {
            color: $primary;
            font-size: 20px;
          }
        }
        .description {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      .downloadButtonContainer {
        height: 100%;
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-end;
        .download {
          font-weight: 600;
          color: $link-blue;
          padding: 0 8px;
          text-align: right;
        }
      }
    }
  }
  .header {
    .logo {
      .logoLink {
        .logoPicture {
          &.activityJa {
            flex-direction: column;
          }
        }

        .logoImg {
          &.activityJa {
            margin-top: -3px;
            width: auto;
            height: 34px;
          }
          &__jpTokyuText {
            display: block;
            margin-top: -8px;
            font-size: 9px;
            color: #9b9b9b;
            letter-spacing: 1px;
          }
        }
      }
    }
    .products {
      .productsBtn {
        position: absolute;
        left: 5px;
        z-index: 1;
        transition: left 0.2s;
      }

      .adjust {
        left: 240px;
        top: 0px;
      }

      .productList {
        top: 0 !important;
        height: 100vh !important;

        &::before {
          top: 0;
          height: 100vh;
        }
      }
    }
    .menu {
      .menuBtn {
        margin-left: 0;
      }
    }
    &.kaimono {
      .products {
        .productList {
          left: -100%;
          width: 100%;
          padding-bottom: 112px;

          &.listOpen {
            left: 0;

            &::before {
              pointer-events: auto;
              opacity: 1;
            }
          }

          .list {
            .anchor {
              @include font-size(24);
              padding: 8px 20px;
              .icon {
                @include font-size(24);
              }
            }
            &:first-of-type {
              margin-top: 16px;
            }
          }
        }
        .adjust {
          left: unset;
          right: 14px;
          top: 14px;
        }
      }
    }
  }
}
