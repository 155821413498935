@import "../../assets/styles/main.scss";

.footer {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  color: $base;
  line-height: 1.65;

  &.ja {
    font-family: -apple-system, BlinkMacSystemFont, "Hiragino Kaku Gothic ProN",
      "Hiragino Sans", Meiryo, sans-serif;
  }

  &.en {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
      Arial, sans-serif;
  }

  &.zh_tw {
    font-family: "Helvetica Neue", Arial, "Microsoft JhengHei", "PingFang TC",
      "Noto Sans TC", sans-serif;
  }

  &.zh_hk {
    font-family: "Helvetica Neue", Arial, "Microsoft JhengHei", "PingFang HK",
      "Noto Sans HK", sans-serif;
  }

  &.zh_cn {
    font-family: "Helvetica Neue", Arial, "Microsoft YaHei", "PingFang SC",
      "Source Han Sans SC", sans-serif;
  }

  &.au {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
      Arial, sans-serif;
  }

  width: 100%;
  color: $white;
  background-color: $gray-900;
  padding-top: 60px;
  margin-top: auto;

  .footerListContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    margin-bottom: 1.2rem;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    @include media-breakpoint-up(sm) {
      max-width: 540px;
    }
    @include media-breakpoint-up(md) {
      max-width: 720px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 960px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 1140px;
    }

    .footerListTitle {
      @include font-size(16);
      margin: 0 0 15px 0;
    }

    .footerList {
      list-style: none;
      padding: 0;
      margin: 0;

      .list {
        padding: 4px 0;

        .anchor {
          font-weight: 400;
          text-decoration: none;
          color: $white;
          transition: all 0.2s;

          &:hover {
            text-decoration: none;
            color: $primary;
          }
        }
      }
    }

    .sns {
      display: flex;

      .list {
        margin-right: 30px;
      }

      [class^="icon-"] {
        @include font-size(30);
      }
    }
  }
  .copyright {
    color: $white;
    font-size: 80%;
    font-weight: 400;
    text-align: center;
    opacity: 0.4;
    padding: 30px 0;
  }
  &.activity{
    .footerListContainer {
      .footerListTitle {
        @include font-size-activity(16);
      }
      .sns {
        [class^="icon-"] {
          @include font-size-activity(30);
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .footer {
    padding-top: 40px;
    .footerListContainer {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
    }
    .copyright {
      text-align: left;
      flex-wrap: wrap;
      padding: 10px 0 20px 0;
    }
  }
}
