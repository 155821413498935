// min-width
$breakpoint-up: (
  'sm': 'screen and (min-width: 576px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 992px)',
  'xl': 'screen and (min-width: 1200px)',
) !default;

// max-width
$breakpoint-down: (
  'sm': 'screen and (max-width: 575.98px)',
  'md': 'screen and (max-width: 767.98px)',
  'lg': 'screen and (max-width: 991.98px)',
  'xl': 'screen and (max-width: 1199.98px)',
) !default;

// @mixinの定義
@mixin media-breakpoint-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin media-breakpoint-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin font-size($size, $base: 16) {
  font-size: calc($size / $base) + rem;
}

@mixin font-size-activity($size, $base: 10) {
  font-size: calc($size / $base) + rem;
}
