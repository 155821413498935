$pink-100: #FFF2F5;
$pink-200: #FFD1DC;
$pink-300: #FFAFC2;
$pink-400: #FA8AA5;
$pink-500: #F06083;
$pink-600: #E91E63;

$gray-100: #F0F1F2;
$gray-200: #E7E9EB;
$gray-300: #DADDE0;
$gray-400: #C9CDD1;
$gray-500: #B4B8BE;
$gray-600: #82878E;
$gray-700: #61666E;
$gray-800: #3E454E;
$gray-900: #2F353E;
$black: #1E2127;
$white: #FFFFFF;

$link-blue: #177BD8;

$primary: $pink-600;
$base: $gray-900;

